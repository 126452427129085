import { useState, useEffect } from "react";
import { Box, Progress, Text, Flex, VStack, Button } from "@chakra-ui/react";
import CompleteProfile from "./CompleteProfile";
import Step1 from "./Step1";
import { useStoreContext } from "../context/StoreContext";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Navbar from "./components/Navbar";
import useStoresData from "../context/getStores";

export default function MultiStepForm() {
  const [step, setStep] = useState(1);
  const [profileData, setProfileData] = useState({});
  const [completedSteps, setCompletedSteps] = useState(new Set());
  const { user, store, storeError } = useStoreContext();
  const { stores, error } = useStoresData();

  const steps = [
    "Store Information",
    "Address Information",
    "Set Business Hours",
    "GST and Bank Details",
    "Upload Documents",
    "Agreement",
    "Payment",
  ];

  const storesArray = Array.isArray(stores) ? stores : stores ? [stores] : [];

  const unpaidStores = storesArray.filter(
    (storeItem) => storeItem?.Payment === false
  );

  const unpaidStoreToSend = unpaidStores[0] || null;

  useEffect(() => {
    // Effect to handle any required side effects related to stores
  }, [storesArray]);

  const handleProfileComplete = (data) => {
    setProfileData(data);
    markStepComplete(1);
    setStep(2);
  };

  const handleStoreComplete = async (storeData, targetStep) => {
    setProfileData((prev) => ({ ...prev, ...storeData }));

    if (
      targetStep === 3 &&
      unpaidStoreToSend?.storeCategory === "669a0b5b877265140df50307"
    ) {
      markStepComplete(2);
      setStep(4);
    } else {
      markStepComplete(targetStep - 1);
      setStep(targetStep);
    }
  };

  const markStepComplete = (completedStep) => {
    setCompletedSteps((prev) => new Set(prev).add(completedStep));
  };

  const handleSidebarClick = (index) => {
    if (index === 1 && unpaidStoreToSend?.StoreName) {
      setStep(2);
    } else if (index === 2 && unpaidStoreToSend?.StoreAddress?.trim()) {
      setStep(3);
    } else if (completedSteps.has(index) || step === index + 1) {
      setStep(index + 1);
    }
  };

  if (storeError) {
    return (
      <Text className="min-h-[100vh] flex items-center justify-center">
        Error loading user or store details. Please check your internet
        connection.
      </Text>
    );
  }

  return (
    <>
      <Navbar user={user} />
      <Flex direction={{ base: "column", md: "row" }} minH="100vh" p={4}>
        {/* Sidebar */}
        <VStack
          spacing={4}
          align="stretch"
          width={{ base: "100%", md: "200px" }}
          bg="gray.50"
          p={4}
          borderRadius="md"
          shadow="sm"
          mb={{ base: 4, md: 0 }}
        >
          {steps.map((label, index) => (
            <Button
              key={index}
              onClick={() => handleSidebarClick(index)}
              isDisabled={!completedSteps.has(index) && step !== index + 1}
              colorScheme={step === index + 1 ? "purple" : "gray"}
              variant={step === index + 1 ? "solid" : "ghost"}
              justifyContent="flex-start"
              fontWeight="normal"
              _hover={{ bg: step === index + 1 ? "purple.500" : "gray.200" }}
              size="sm"
            >
              {label}
            </Button>
          ))}
        </VStack>

        {/* Main Content */}
        <Box flex="1" p={6} bg="white" borderRadius="md" shadow="md">
          <Progress
            value={(step / steps.length) * 100}
            size="xs"
            colorScheme="purple"
            mb={4}
          />

          <Box p={4} borderRadius="md" shadow="sm" bg="white">
            {step === 1 && (
              <Step1
                onNext={(data) => handleStoreComplete(data, 2)}
                userStores={unpaidStoreToSend}
              />
            )}
            {step === 2 && (
              <Step2
                onNext={(data) => handleStoreComplete(data, 3)}
                profileData={profileData}
                store={unpaidStoreToSend}
              />
            )}
            {step === 3 && (
              <Step3
                onNext={(data) => handleStoreComplete(data, 4)}
                profileData={profileData}
                store={unpaidStoreToSend}
              />
            )}
            {step === 4 && (
              <Step4
                onNext={(data) => handleStoreComplete(data, 5)}
                profileData={profileData}
                store={unpaidStoreToSend}
              />
            )}
            {step === 5 && (
              <Step5
                onNext={(data) => handleStoreComplete(data, 6)}
                profileData={profileData}
                store={unpaidStoreToSend}
              />
            )}
            {step === 6 && (
              <Step6
                onNext={(data) => handleStoreComplete(data, 7)}
                profileData={profileData}
                store={unpaidStoreToSend}
              />
            )}
            {step === 7 && (
              <Step7
                onNext={(data) => handleStoreComplete(data, 8)}
                user={profileData}
                store={unpaidStoreToSend}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
}
