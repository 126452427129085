import axios from "axios";
import Cookies from "js-cookie";
import { useState, useEffect, useRef } from "react";

const useStoresData = ({
  endpoint = "https://api.notatmrp.com/auth/retailer/stores/",
  refreshInterval = 3000,
  apiKey = "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
} = {}) => {
  const [stores, setStores] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const cachedStore = useRef(null);
  const controllerRef = useRef(null); // For request cancellation

  const hasDataChanged = (newData, cachedData) => {
    if (!cachedData || !newData) return true;
    return (
      newData.length !== cachedData.length ||
      newData.some(
        (item, index) =>
          JSON.stringify(item) !== JSON.stringify(cachedData[index])
      )
    );
  };

  const fetchData = async () => {
    const token = Cookies.get("notatmrp_seller");
    if (!token) {
      setError("No token found");
      setLoading(false);
      return;
    }

    try {
      if (controllerRef.current) controllerRef.current.abort();
      controllerRef.current = new AbortController();

      const res = await axios.get(endpoint, {
        headers: {
          "x-api-key": apiKey,
          Authorization: `Bearer ${token}`,
        },
        signal: controllerRef.current.signal,
      });

      if (hasDataChanged(res.data, cachedStore.current)) {
        cachedStore.current = res.data;
        setStores(res.data);
      }
      setError(null);
    } catch (err) {
      if (axios.isCancel(err)) {
        console.warn("Request canceled:", err.message);
      } else {
        console.error("Failed to fetch store data:", err);
        setError("Failed to fetch store data");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, refreshInterval);

    return () => {
      clearInterval(intervalId);
      if (controllerRef.current) controllerRef.current.abort();
    };
  }, [endpoint, refreshInterval, apiKey]);

  return { stores, error }; // Return `stores` and `error`
};

export default useStoresData;
