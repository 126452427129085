import "./assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import "./scss/App.scss";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import MainLayout from "./layout/MainLayout";
import "./index.css";
import OrderList from "./pages/OrderList";
import CustomerList from "./pages/CustomerList";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import Home from "./pages/Home";
import AddStores from "./pages/AddStores";
import MultiStepForm from "./pages/MultiStepForm";
import AddCashback from "./components/cashback/AddCashback";
import AddOffers from "./pages/AddOffers";
import AddEvents from "./components/event/AddEvents";
import AddMenu from "./components/menu/AddMenu";
import AddNotification from "./components/notification/AddNotification";
import UpdateStore from "./pages/EditStore";
import EditProfile from "./pages/EditProfile";
import AddTicket from "./pages/AddTicket";
import Booking from "./pages/Booking";
import EventDashboard from "./pages/EventDashboard";
import EventEditStore from "./pages/EventEditStore";
import { StoreProvider, useStoreContext } from "./context/StoreContext";
import CompleteProfile from "./pages/CompleteProfile";

function AppRoutes() {
  const { user, store, hasSpecialStoreCategory } = useStoreContext();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoutes>
            <MainLayout user={user} store={store} />
          </ProtectedRoutes>
        }
      >
        {hasSpecialStoreCategory ? (
          <Route
            index
            element={
              <ProtectedRoutes>
                <EventDashboard />
              </ProtectedRoutes>
            }
          />
        ) : (
          <Route
            index
            element={
              <ProtectedRoutes>
                <Dashboard store={store} />
              </ProtectedRoutes>
            }
          />
        )}
        <Route
          path="cashback"
          element={
            <ProtectedRoutes>
              <AddCashback user={user} store={store} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="addoffers"
          element={
            <ProtectedRoutes>
              <AddOffers store={store} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="addevents"
          element={
            <ProtectedRoutes>
              <AddEvents store={store} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="addmenu"
          element={
            <ProtectedRoutes>
              <AddMenu store={store} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="addnotification"
          element={
            <ProtectedRoutes>
              <AddNotification store={store} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="orders"
          element={
            <ProtectedRoutes>
              <OrderList store={store} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="customers"
          element={
            <ProtectedRoutes>
              <CustomerList />
            </ProtectedRoutes>
          }
        />
        <Route path="addstores" element={<AddStores />} />
        <Route
          path="settings"
          element={
            <ProtectedRoutes>
              <UpdateStore store={store} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="editstore"
          element={
            <ProtectedRoutes>
              <EventEditStore store={store} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="editprofile"
          element={
            <ProtectedRoutes>
              <EditProfile user={user} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="addticket"
          element={
            <ProtectedRoutes>
              <AddTicket store={store} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="bookings"
          element={
            <ProtectedRoutes>
              <Booking store={store} />
            </ProtectedRoutes>
          }
        />
      </Route>
      <Route path="completeprofile" element={<CompleteProfile user={user} />} />
      <Route path="addstore" element={<MultiStepForm user={user} />} />

      <Route path="login" element={<Home />} />
    </Routes>
  );
}

export default function App() {
  return (
    <StoreProvider>
      <AppRoutes />
    </StoreProvider>
  );
}
