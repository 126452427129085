import {
  Box,
  Button,
  Input,
  Heading,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Divider,
  Flex,
  Stack,
  Image,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles for ReactQuill

const Step1 = ({ onNext, userStores }) => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    StoreName: "",
    StoreCity: "",
    legalName: "",
    StoreContact: "",
    StoreCategory: "",
    About: "",
    Description: "",
    TermsAndConditions: "",
    Social_handles: {
      instagram: "",
      facebook: "",
      twitter: "",
      youtube: "",
    },
  });
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch store categories from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://api.notatmrp.com/category/all/",
          {
            headers: {
              "x-api-key":
                "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
            },
          }
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Set form data if userStores contains data
  useEffect(() => {
    if (userStores) {
      setFormData({
        StoreName: userStores.StoreName || "",
        StoreCity: userStores.StoreCity || "",
        legalName: userStores.legalName || "",
        StoreContact: userStores.StoreContact || "",
        StoreCategory: userStores.StoreCategory || "",
        About: userStores.About || "",
        Description: userStores.Description || "",
        TermsAndConditions: userStores.TermsAndConditions || "",
        Social_handles: userStores.Social_handles || {
          instagram: "",
          facebook: "",
          twitter: "",
          youtube: "",
        },
      });
    }
  }, [userStores]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name in formData.Social_handles) {
      setFormData((prevData) => ({
        ...prevData,
        Social_handles: {
          ...prevData.Social_handles,
          [name]: value,
        },
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleQuillChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);

    const token = Cookies.get("notatmrp_seller");

    try {
      const unpaidStore = userStores && !userStores.Payment;

      if (unpaidStore) {
        const response = await axios.patch(
          `https://api.notatmrp.com/stores/${userStores._id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-api-key":
                "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
            },
          }
        );
        if (response.status === 200) {
          toast({
            title: "Details updated successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onNext(formData);
        }
      } else {
        const response = await axios.post(
          "https://api.notatmrp.com/stores",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-api-key":
                "d4a55ce6903feac86108816623cbbb5fc6d181775f1f873d993232e9113bab0b",
            },
          }
        );
        if (response.status === 201) {
          toast({
            title: "Details saved successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onNext(formData);
        }
      }
    } catch (error) {
      setError("Failed to save data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      maxW={{ base: "90%", md: "600px" }}
      mx="auto"
      py={10}
      px={{ base: 4, md: 8 }}
    >
      <Flex justifyContent="center" mb={4}>
        <Image
          src="https://www.notatmrp.com/_next/image?url=%2Fassets%2Fblogo.png&w=256&q=75"
          width={200}
        />
      </Flex>
      <Heading textAlign="center" mb={4} color="purple.700">
        Welcome!
      </Heading>
      <Text textAlign="center" mb={8} color="gray.600" fontSize="lg">
        Please fill out the necessary information to get started. You can update
        these details later.
      </Text>
      <VStack spacing={6} align="stretch">
        {/* Form Inputs */}
        <FormControl id="store-name" isRequired>
          <FormLabel>Store Name</FormLabel>
          <Input
            name="StoreName"
            placeholder="Enter your store name"
            value={formData.StoreName}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="legal-name">
          <FormLabel>Legal Name</FormLabel>
          <Input
            name="legalName"
            placeholder="Enter the legal name"
            value={formData.legalName}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="store-city" isRequired>
          <FormLabel>Store City</FormLabel>
          <Input
            name="StoreCity"
            placeholder="Enter your store city"
            value={formData.StoreCity}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="store-category" isRequired>
          <FormLabel>Store Category</FormLabel>
          <Select
            name="StoreCategory"
            value={formData.StoreCategory}
            onChange={handleChange}
            placeholder="Select category"
          >
            {categories
              .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
              .map((category) => (
                <option
                  key={category._id}
                  value={category._id}
                  className="text-purple-800"
                >
                  {category.categoryName}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl id="store-contact" isRequired>
          <FormLabel>Store Contact</FormLabel>
          <Input
            name="StoreContact"
            placeholder="Enter the WhatsApp Number"
            value={formData.StoreContact}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="about">
          <FormLabel>About</FormLabel>
          <Input
            name="About"
            placeholder="Tell us about your store"
            value={formData.About}
            onChange={handleChange}
          />
        </FormControl>
        {/* <FormControl id="description">
          <FormLabel>Description</FormLabel>
          <Input
            name="Description"
            placeholder="Enter a description"
            value={formData.Description}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="terms-and-conditions">
          <FormLabel>Terms and Conditions</FormLabel>
          <Input
            name="TermsAndConditions"
            placeholder="Enter terms and conditions"
            value={formData.TermsAndConditions}
            onChange={handleChange}
          />
        </FormControl> */}

        <FormControl id="description">
          <FormLabel>Description</FormLabel>
          <ReactQuill
            theme="snow"
            value={formData.Description}
            onChange={(value) => handleQuillChange("Description", value)}
            placeholder="Enter store description"
          />
        </FormControl>
        <FormControl id="terms-and-conditions">
          <FormLabel>Terms and Conditions</FormLabel>
          <ReactQuill
            theme="snow"
            value={formData.TermsAndConditions}
            onChange={(value) => handleQuillChange("TermsAndConditions", value)}
            placeholder="Enter terms and conditions"
          />
        </FormControl>

        <Divider my={6} />

        {/* Social Handles */}
        <Heading size="md" color="purple.700" mb={2}>
          Social Handles
        </Heading>
        <Stack direction={{ base: "column", md: "row" }} spacing={4}>
          <FormControl id="instagram">
            <FormLabel>Instagram</FormLabel>
            <Input
              name="instagram"
              placeholder="Instagram handle"
              value={formData.Social_handles.instagram}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="facebook">
            <FormLabel>Facebook</FormLabel>
            <Input
              name="facebook"
              placeholder="Facebook handle"
              value={formData.Social_handles.facebook}
              onChange={handleChange}
            />
          </FormControl>
        </Stack>
        <Stack direction={{ base: "column", md: "row" }} spacing={4}>
          <FormControl id="twitter">
            <FormLabel>Twitter</FormLabel>
            <Input
              name="twitter"
              placeholder="Twitter handle"
              value={formData.Social_handles.twitter}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="youtube">
            <FormLabel>YouTube</FormLabel>
            <Input
              name="youtube"
              placeholder="YouTube channel link"
              value={formData.Social_handles.youtube}
              onChange={handleChange}
            />
          </FormControl>
        </Stack>

        <Button
          colorScheme="purple"
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          Next
        </Button>

        {error && <Text color="red.500">{error}</Text>}
      </VStack>
    </Box>
  );
};

export default Step1;
